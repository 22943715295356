import { emailGreetings } from '@app/constants'
import { AccountResponse, ContactResponse, EmailResponse, LeadResponse, OpportunityResponse, SiteResponse } from '@app/types'
export function generateGreeting(name: string) {
  const rand = Math.floor(Math.random() * emailGreetings.length)
  return emailGreetings[rand].replace('[Placeholder]', name)
}

type Entity = LeadResponse | AccountResponse | ContactResponse | OpportunityResponse | SiteResponse

export function formatEntityEmailData(obj: Entity) {
  const entityType = findEntityType(obj)
  const entityName = findEntityName(obj)
  const addresses = findEmailAddresses(obj)
  const entityMailTo = formatMailTo(entityType, entityName, addresses)

  return {
    type: entityType,
    name: entityName,
    addresses: addresses,
    mailto: entityMailTo,
    data: obj,
  }
}

function formatMailTo(type: string, name: string, addresses: EmailResponse[]) {
  let subject, body, mailto
  if (type && name) {
    type === 'opportunity' ? (subject = `subject=${name}`) : (subject = '')
    type === 'lead' || type === 'contact' ? (body = `body=${generateGreeting(name)}`) : (body = '')
    addresses.length ? (mailto = `mailto:${addresses[0].address}${body !== '' ? `?${body}` : body}${subject !== '' ? `&${subject}` : subject}`) : ''
    return mailto
  }
  return ''
}

function findEmailAddresses(obj: Entity) {
  if ('emails' in obj) {
    // REVIEW: Why is this just not return obj.emails
    return obj.emails?.map((email) => email) || []
  }
  return []
}

function findEntityName(obj: Entity) {
  if ('name' in obj) {
    return obj.name
  }

  if ('firstName' in obj && 'lastName' in obj) {
    return `${obj.firstName} ${obj.lastName}`
  }

  return null
}

function findEntityType(obj: Entity) {
  function isAccount(obj: Entity): obj is AccountResponse {
    return 'accountStatus' in obj && !('owner' in obj)
  }

  function isLead(obj: Entity): obj is LeadResponse {
    return 'primaryRep' in obj && 'quality' in obj
  }

  function isContact(obj: Entity): obj is ContactResponse {
    return 'contactType' in obj
  }

  function isOpportunity(obj: Entity): obj is OpportunityResponse {
    return 'opportunityDescription' in obj
  }

  function isSite(obj: Entity): obj is SiteResponse {
    return 'owner' in obj
  }

  if (isAccount(obj)) {
    return 'account'
  } else if (isLead(obj)) {
    return 'lead'
  } else if (isContact(obj)) {
    return 'contact'
  } else if (isOpportunity(obj)) {
    return 'opportunity'
  } else if (isSite(obj)) {
    return 'site'
  }
  return null
}

export function parseAddressInMailto(mailTo: string): string[] | null {
  const regex = /[\w\.-]+@[\w\.-]+\.\w+/g
  return mailTo.match(regex)
}

export const FSG_EMAIL_ALIASES = [
  'fsgi.com',
  'americanlight.com',
  'commerciallighting.com',
  'designelectric.com',
  'fsg.com',
  'fsg1.com',
  'fsgconnect.com',
  'propelcareeracademy.com',
  'propelprefab.com',
  'terousa.com',
  'west-lite.com',
]

type EmailValidatorConfig = {
  allowFsg: boolean
}
const EmailValidatorConfigDefaults = {
  allowFsg: true,
}
export class EmailValidator {
  constructor(private config: EmailValidatorConfig = EmailValidatorConfigDefaults) {}

  static validEmailRegexp = new RegExp(
    /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/,
  )

  private matchFsgEmail(value: string) {
    const escapedDomains = FSG_EMAIL_ALIASES.map((domain) => domain.replace(/[.-]/g, '\\$&'))

    const regexp = new RegExp(`^(?!.*(${escapedDomains.join('|')})).*$`, 'i')
    return regexp.test(value)
  }

  // Must be an arrow fn in order to use as a callback anywhere
  public validate = (value: string) => {
    if (!value || !value.trim() || EmailValidator.validEmailRegexp.test(value) === false) {
      return 'The email address entered is invalid'
    }

    if (this.config.allowFsg === false && this.matchFsgEmail(value) === false) {
      return 'FSG email aliases are not allowed.'
    }

    return true
  }
}
